.app-window-container {
  display: flex;
  position: absolute;
  box-sizing: border-box;
  background-color: #FFF;
  box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.75);
  padding-top: 35px;
  color: #FFF;
  overflow: hidden;
  
  .ui-resizable { position: relative;}
  .ui-resizable-handle { position: absolute;font-size: 0.1px;z-index: 99999; display: block;}
  .ui-resizable-disabled .ui-resizable-handle, .ui-resizable-autohide .ui-resizable-handle { display: none; }
  .ui-resizable-n { cursor: n-resize; height: 7px; width: 100%; top: -5px; left: 0; }
  .ui-resizable-s { cursor: s-resize; height: 7px; width: 100%; bottom: -5px; left: 0; }
  .ui-resizable-e { cursor: e-resize; width: 7px; right: -5px; top: 0; height: 100%; }
  .ui-resizable-w { cursor: w-resize; width: 7px; left: -5px; top: 0; height: 100%; }
  .ui-resizable-se { cursor: se-resize; width: 12px; height: 12px; right: 1px; bottom: 1px; }
  .ui-resizable-sw { cursor: sw-resize; width: 9px; height: 9px; left: -5px; bottom: -5px; }
  .ui-resizable-nw { cursor: nw-resize; width: 9px; height: 9px; left: -5px; top: -5px; }
  .ui-resizable-ne { cursor: ne-resize; width: 9px; height: 9px; right: -5px; top: -5px;}  
  // .ui-resizable-handle {
  //   width: 20px;
  //   height: 20px;
  //   position: absolute;
  //   z-index: 10;

  //   &.top {
  //     top: 0;
  //   }

  //   &.bottom {
  //     bottom: 0;
  //   }
    
  //   &.left {
  //     left: 0;
  //   }
    
  //   &.right {
  //     right: 0;
  //   }    
  // }
}

  
.app-window-header {
  height: 35px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #FFF;
  border-bottom: 1px solid #ddd;
  box-shadow: 0px 5px 10px 0px rgba(0,0,0,0.35);
  display: flex;
  align-items: center;
  padding-right: 10px;
  box-sizing: border-box;
  gap: 15px;
  

  .app-window-title {
    margin: 0;
    padding: 1em 0.5em;
    font-size: 0.8em;
    white-space: nowrap;
    width: 80%;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .header-button {
    width: 13px;
    height: 13px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  .minimise-button {
    background-image: url('../images/minimise.svg');
    margin-left: auto;      
  }

  .fullscreen-button {
    background-image: url('../images/full-screen.svg');
  }
  
  .close-button {
    background-image: url('../images/close.svg');      
  }    
}