body {
  margin: 0;
  width: 100vw;
  height: 100vh;  
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
}

main {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.desktop-icon-container {

  display: flex;
  flex-direction: column;
  position: absolute;
  right: 2em;
  top: 5%;
  gap: 2em;

  .desktop-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    img {
      width: 75px;
      height: auto;
    }

    p {
      margin: 0;
      font-size: 0.8em;
      text-align: center;
      color: #FFF;
      text-shadow: 2px 2px #000;
    }

  }
}