.map-view-container {

  display: flex;
  width: 100%;
  height: 100%;

  .map-container {
    height: 100%;
    width: 100%;
  }  
  
  .marker {      
      background-size: contain;
      background-repeat: no-repeat;
      width: 50px;
      height: 50px;
      cursor: pointer;

      &.marker-0 {
        background-image: url('../images/map-icon-1.png');
      }

      &.marker-1 {
        background-image: url('../images/map-icon-2.png');
      }
      
      &.marker-2 {
        background-image: url('../images/map-icon-3.png');
      }      
  }
     
  .mapboxgl-ctrl-logo, .mapboxgl-ctrl-attrib-inner {
    display: none !important;
  }

  .mapboxgl-popup {
     max-width: 100000px !important;

     &.popup-0 .mapboxgl-popup-content, &.popup-0 .mapboxgl-popup-content h3 {
      background-color: #10048a;
    }

    &.popup-1 .mapboxgl-popup-content, &.popup-1 .mapboxgl-popup-content h3 {
      background-color: #6be92f;
    }
    
    &.popup-2 .mapboxgl-popup-content, &.popup-2 .mapboxgl-popup-content h3 {
      background-color: #ff389f;
    }        
  }
     
  .mapboxgl-popup-content {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    padding: 4px;
    padding-bottom: 0;
    position: relative;

    h3 {
      position: absolute;
      top: 0;
      left: 0;
      transform: translateY(-110%);
      padding: 1em;
      color: #aaa;
      text-transform: uppercase;
      font-size: 1em;
      line-height: 1em;
      text-align: right;
    }
    
    img, video {
      max-height: 200px;
      position: relative;
      z-index: 10;
    }
  }  

  .mapboxgl-popup-tip {
    display: none;
  }
}