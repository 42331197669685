.security-cam-view-container {
  height: 50%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;

  .camera-top, .camera-bottom {
    height: 100%;
    width: auto;
    position: absolute;
    top: 0;
    left: 0;
  }

  .camera-top {
    transform-origin: 44.71% 53.68%;
    transform: rotate(5deg);
    top: 8%;    
  }  

}