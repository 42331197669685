.virus-view-container {
  background-color: #FFF;
  box-sizing: border-box;
  padding: 2em;
  width: 100%;
  position: relative;

  video {
    position: absolute;
    top: 0; left: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .live-container {
    position: absolute;
    width: 50px;
    right: 1em;
    top: 1em;    
    display: flex;
    align-items: center;

    .dot {
      background-color: #FF0000;
      height: 10px;
      width: 10px;
      border-radius: 10px;
      animation: flash 0.75s infinite;            
      animation-direction: alternate;
      animation-timing-function: ease-in-out;        
    }

    p {
      color: #FF0000;
      margin: 0;
      margin-left: 5px;
    }
  }

}

@keyframes flash {
  0% { opacity: 0; }
 100% { opacity: 1; }
}