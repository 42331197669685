.backgrounds-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #000;

  .background {
    display: flex;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
  }

  .ticker {
    display: flex;
    width: 110vw;
    height: auto;
    object-fit: cover;    
    position: absolute;
    left: -5%;
    top: 5%;
  }

}

//mobile
@media screen and (max-width: 35em) {

  .backgrounds-container {
    .ticker {
      height: 10%;
    }
  }
}