.loader-container {
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: #0000FF;
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;  

  .title {
    color: #0000FF;
    background-color: #DDD;
    font-family: monospace;
    font-size: 1.8em;
    word-wrap: break-word;
    padding: 0.5em 1em;
    text-align: center;
    margin-bottom: 3em;
  }

   .Typewriter {
    //  width: 100vw;
     padding: 1.5em;
     box-sizing: border-box;
   }

  .type-out {
    color: rgb(255,255,255);
    font-family: monospace;
    font-size: 1.8em;
    line-height: 2.5em;
    
    word-wrap: break-word;
    text-align: center;
    align-items: center;
  }

  .Typewriter__cursor {
    background-color: rgb(255,255,255);
    color: rgb(255,255,255);
  }
}