.how-to-view-container {
  background-color: #FFF;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 2em;
  width: 100%;
  
  h1 {
    width: 100%;
    text-align: left;
    text-decoration: underline;
    color: rgb(0,0,255);
    font-size: 2em;
  }

  p {
    width: 100%;
    text-align: left;
    color: #000;   
    font-size: 1em;
  }

}