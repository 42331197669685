.disclaimer-view-container {
  background-color: #FFF;
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 2em;
  width: 100%;
  color: #000; 
  
  h1 {
    width: 100%;
    text-align: left;
    text-decoration: underline;    
    font-size: 1em;
  }

  p {
    width: 100%;
    text-align: left;
    color: #000;   
    font-size: 1em;
  }

  a {
    color: #000;
  }



}