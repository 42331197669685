.cam-list-view-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 100%;
  gap: 2%;
  row-gap: 6%;
  padding: 2%;
  overflow-y: scroll;
  container-type: size;
  box-sizing: border-box;
  
  .camera-container {

     &.camera-container-0, &.camera-container-0 h3 {
     // background-color: #10048a;
     background-color: #00FF00;
    }

    &.camera-container-1, &.camera-container-1 h3 {
      background-color: #00FF00;
    }
    
    &.camera-container-2, &.camera-container-2 h3 {
     // background-color: #ff389f;
     background-color: #00FF00;
    }        
  }
     
  .camera-container {
    text-align: center;
    font-family: 'Open Sans', sans-serif;
    padding: 4px;
    padding-bottom: 0;
    position: relative;
    box-sizing: border-box;
    width: 48%;
    max-width: 300px;
    max-height: 300px;
    height: 50%;
    overflow: hidden;
        
    h3 {
      position: absolute;
      top: 0;
      right: 0;
      padding: 1em;
      margin: 0;
      width: 35%;
      color: #000;
      text-transform: uppercase;
      font-size: 0.7em;
      line-height: 1em;
      text-align: right;
      z-index: 11;
    }

    p {
      position: absolute;
      bottom: 0;
      right: 0;
      padding: 1em;
      margin: 0;
      box-sizing: border-box;
      width: 100%;
      color: #000;
      font-size: 0.9em;
      line-height: 1em;
      text-align: center;
      z-index: 11;      
      border-top: 2px solid #00FF00;
      background-color: #f1f1f1;
    }
    
    img, video {
     // max-height: 200px;
      background-color: #000;
      width: 100%;
      height: 98%;
      position: relative;
      z-index: 10;
      object-fit: cover;
    }
  }  

}

@container (max-width: 340px) {
  .cam-list-view-container .camera-container {
    width: 99%;
    max-width: 450px;
    max-height: 450px;
    height: 30%;
  }
}  


