.trash-view-container {
  background-color: #FFF;
  overflow-y: scroll;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: row;

  .side-container {
    height: 100%;
    width: 100px;
    border-right: 1px solid #000;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      width: 60%;
      height: auto;
      display: block;
      margin-top: 100%;      
    }

  }

  .trash-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    text-decoration: none;

    &.link {
      margin-top: 2em;
      margin-left: 2em;
    }

    img {
      width: 60px;
      height: auto;
    }

    p {
      margin: 0;
      margin-top: 5px;
      font-size: 0.8em;
      text-align: center;
      color: #000;
      text-shadow: 2px 2px #FFF;
      text-decoration: none;
    }

  }
  
}