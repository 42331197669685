.music-video-view-container {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: black;
  
  .music-video {
    height: 100%;
    width: 100%;
    
  }  
  
}